// npm modules
import React, { useState, useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import { Link} from 'react-router-dom';
import { connect } from 'react-redux';
import validate from "validate.js";
import {isEmpty, isNil, omitBy} from "lodash";
import axios from "axios";
import randomstring from "randomstring";
import { parse as parseQuery } from "query-string";


// styles
import './logIn.css';
import styles from './login.module.css';

// actions
import { login, checkLogin, } from "../store/actions/authActions";

// components
import Loader from 'react-loader-spinner';
import { STATUSES } from "../serverVariables/statuses";
import TermsOfService from "./signUp/termsOfService/termsOfService";
import {useWindowSize} from "../hooks/useWindowSize";
import DaxkoSignup from "./daxkoSignup/daxkoSignup";
import {fetchDaxkoUser} from "../store/actions/organizationActions";

const pages = {
    LOGIN: 'login',
    SIGN_UP: 'sign up',
    DEMO: 'demo'
};

const Login = (props) => {
    // state
    const { appState, user } = props;
    // props
    const { location, history } = props;
    // actions
    const { login, checkLogin, fetchDaxkoUser } = props;

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [policies, setPolicies] = useState([]);
    const {height} = useWindowSize();
    const tosRef = useRef();
    const [page, setPage] = useState(pages.LOGIN);
    const [orgId, setOrgId] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const [ validation, setValidation ] = useState({});
    const [daxkoAuth, setDaxkoAuth] = useState(null);
    const [daxkoState] = useState(
        randomstring.generate({ length: 10, charset: "alphanumeric" })
    );
    const [returnedDaxkoState, setReturnedDaxkoState] = useState(null);

    //when appState is updated check status
    useEffect(() => {
        if (appState.isLoggedIn && appState.appReady && window.location.href.lastIndexOf('localhost') > 0) {
            const { from } = location.state || { from : null };
            if (from) {
                history.push(from);
            }
            if (location.state && location.state.linkTo) {
                history.push(location.state.linkTo);
                return;
            }
        }
    }, [ appState, history, location ]);

    useEffect(() => {
      if (returnedDaxkoState && inviteCode && orgId) {
        const stateCheck = parseQuery(props.location.search).state;
        if (returnedDaxkoState === stateCheck) {
          const code = parseQuery(props.location.search).code;
          fetchDaxkoUser(code)
            .then((res) => {
                history.push(`/signup?inviteCode=${inviteCode}&orgId=${orgId}&d_firstName=${res.first_name}&d_lastName=${res.last_name}&d_member=${res.daxko_member_id}`);
            })
            .catch((e) => console.log(e));
        }
      }
    }, [returnedDaxkoState, inviteCode, orgId]);

    // check if a user is logged in when mounting
    useEffect(() => {
        // check if a code was present
        const code = parseQuery(props.location.search).code;
        if (code) {
            const cookie1 = getCookie("daxkoStateVal");
            const cookie2 = getCookie("daxkoOrgId");
            const cookie3 = getCookie("daxkoOrgCode");
            eraseCookie("daxkoStateVal");
            eraseCookie("daxkoOrgId");
            eraseCookie("daxkoOrgCode");
            setOrgId(cookie2);
            setInviteCode(cookie3);
            setReturnedDaxkoState(cookie1);
            setLoading(true);
        }

        checkLogin()
            .then(() => setLoading(false));
    }, []);

    useEffect(() => {
        document.body.style.background = '#fff';
        return () => document.body.style.background = null;
    },[policies]);

    // forward a user to the appropriate place
    useEffect(() => {
        if (appState.appReady && !appState.isLoggedIn && location && location.state && location.state.from && location.state.from.search) {
            history.push({search: location.state.from.search});
        }
        if (location.state && window.location.href.lastIndexOf('localhost') > 0) return;
        if (user && appState.isLoggedIn && appState.appReady) {
            if(user.permission_id === 1){
                if(user.user_status_id < STATUSES.onboarding_coach_consult_pending){
                    //client is in onboarding
                    history.push({
                        pathname : `/member/onboard-assessment`,
                    });
                }else{
                    // Forward to dashboard
                    let url = { pathname: `/member/clientDash/${user.username}` };

                    if (location.state && location.state.from && location.state.from.search){
                        url.search = location.state.from.search;
                    }

                    if (location.state && location.state.from) {
                    const fromPathname = location.state.from.pathname;

                    // Check if the URL matches /account/notifications
                    const pattern = /.*\/account\/notifications/gm;
                    const isNotifications = pattern.test(fromPathname);

                    // Check if the URL matches /member/clientDash/{user.email}/assessments
                    const patternAssessment = /\/member\/clientDash\/[^/]+\/assessments/;
                    const isAssessments = patternAssessment.test(fromPathname);

                    if (isNotifications) {
                        url = location.state.from;
                    } else if (isAssessments) {
                        url = location.state.from;
                    }
                    }
                    history.push(url);
                }
            }else if(user.permission_id === 2){
                history.push({
                    pathname: '/dashboard/analytics/user'
                });
            }else{
                history.push({
                    pathname:'/dashboard/analytics/user'
                });
            }
        }
    }, [ appState ]);


    function logInUser(e) {
        e.preventDefault();
        login(email, password)
            .catch(error => {
                setError('Invalid email or password');
        });
    }

    async function signupUser(e) {
        e.preventDefault();
        const errors = {};
        const constraints = {
            orgId: {
                type: {
                    type: 'integer',
                    message: '^Invalid Organization ID'
                },
                presence:{
                    message: '^Organization ID is required'
                },
            },
            inviteCode: {
                type:'string',
                presence:'^Organization code is required',
                length: {
                    minimum: 1,
                    maximum: 255,
                    tooShort: '^Organization code is required',
                    tooLong: '^Organization code must be less than 255 characters',
                }
            }
        };
        const validation = validate({ orgId: parseInt(orgId), inviteCode }, constraints);
        if (validation) {
            errors.orgId = validation.orgId;
            errors.inviteCode = validation.inviteCode;
        }
        setValidation(errors);
        if (!isEmpty(omitBy(errors, isNil))) return;

        try {
            // test to see if code is valid
            const { data: { result: valid } } = await axios.get(`/organizations/check/code/${orgId}/${inviteCode}`);
            // check to see if seats available
            // const seatsRes = await axios.get(`/organizations/new-client/check/${orgId}`);
            // const { data: { result: seats } } = seatsRes;

            setError('');
            // if (valid.daxko_scope && seats) {
            //     createCookie("daxkoStateVal", daxkoState);
            //     createCookie("daxkoOrgId", orgId);
            //     createCookie("daxkoOrgCode", inviteCode);
            //     return setDaxkoAuth(valid.daxko_scope);
            // }
            if (valid) history.push(`/signup?inviteCode=${inviteCode}&orgId=${orgId}`);

        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error);
            } else {
                setError('Unable to verify code. Please contact your Treo administrator at support@treowellness.com');
            }
        }
    }

    function LoginForm() {
        return (
            <form onSubmit={logInUser} className={styles.formContainer}>
                <div className={styles.form}>
                    <p className={styles.formTitle}>Login</p>
                    {error ? <span className={'text-danger'}>{error}</span> : null}
                    <div>
                        <label htmlFor="email">Email</label>
                        <input type="text" id='email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="password">Password</label>
                        <input type="password" id='password' name='password' value={password} onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div>
                        <div className={styles.formSubmit}>
                            <button className='btn primary' type='submit' onClick={logInUser}>Submit</button>
                            <Link to={'/password-reset'} className='link'>
                                Forgot Password
                            </Link>
                        </div>
                        <p>Need help? <a href="mailto:support@treowellness.com">support@treowellness.com</a></p>
                    </div>
                </div>
            </form>
        )
    }

    function SignUpForm() {
        return (
            <form className={styles.formContainer} onSubmit={signupUser}>
                <div className={styles.form}>
                    <p className={styles.formTitle}>Sign Up</p>
                    {error ? <span className={'text-danger'}>{error}</span> : null}
                    <div>
                        <label htmlFor="orgId">Organization Id</label>
                        <input type="text" id='orgId' name='orgId' value={orgId} onChange={e => setOrgId(e.target.value)}/>
                        {validation.orgId ? validation.orgId.map(err => <span className='text-danger'>{err}</span>) : null}
                    </div>
                    <div>
                        <label htmlFor="code">Code</label>
                        <input type="text" id='code' name='code' value={inviteCode} onChange={e => setInviteCode(e.target.value)}/>
                        {validation.inviteCode ? validation.inviteCode.map(err => <span className='text-danger'>{err}</span>) : null}
                    </div>
                    <div className={styles.formSubmit}>
                        <button className='btn primary' type='submit' onClick={signupUser}>Submit</button>
                        <p>Need help? <a href="mailto:support@treowellness.com">support@treowellness.com</a></p>
                    </div>
                </div>

            </form>
        )
    }

    function Footer() {
        return (
            <div ref={tosRef} className={styles.formFooter}>
                <TermsOfService loadPolicies={setPolicies} horizontal/>
            </div>
        )
    }

    function changePage(page) {
        setError('')
        if (page === pages.DEMO) {
            return window.open(
                'https://treowellness.com/schedule-demo/',
                '_blank'
            );
        }
        if (page === pages.SIGN_UP) {
            setEmail('');
            setPassword('');
        }
        if (page === pages.LOGIN) {

        }
        setPage(page);
    }

    if (loading) {
        return (
            <div style={{height}} className='flex-vertically'>
                <Loader
                    type="Puff"
                    color="#354052"
                    height="100"
                    width="100"
                />
            </div>
        )
    }

    if (daxkoAuth) {
       return <DaxkoSignup scope={daxkoAuth} state={daxkoState} />
    }

    return (
        <div className={styles.main}>
            <div className={styles.green}>
                <img className={styles.logo} src="https://treo.s3.us-east-2.amazonaws.com/assets/Logo_White.png"
                     alt="Logo"/>
                <div className={styles.headingContainer}>
                    <h1 className={styles.heading1}>Change is Possible with Treo Wellness</h1>
                    <h2 className={styles.heading2}>We are your whole-person wellbeing app designed to help you discover
                        your healthy lifestyle. Get started today!</h2>
                </div>
            </div>
            <div className={styles.white}>
                <div className={styles.formSelector}>
                    <div className={styles.navLogin}>
                        <button onClick={() => changePage(pages.LOGIN)} className={`${styles.navBtn} ${page === pages.LOGIN ? styles.active : null}`}>Login</button>
                        <button onClick={() => changePage(pages.SIGN_UP)} className={`${styles.navBtn} ${page === pages.SIGN_UP ? styles.active : null}`}>Sign Up</button>
                        <button onClick={() => changePage(pages.DEMO)} className={styles.navBtn}>Book a Demo</button>
                    </div>
                    {page === pages.LOGIN ? LoginForm() : null}
                    {page === pages.SIGN_UP ? SignUpForm() : null}
                    {Footer()}
                </div>
            </div>
        </div>
    )
}

function mapStateToProps({ appState, user }) {
    return {
        appState,
        user,
    }
}

const actions = {
    login,
    checkLogin,
    fetchDaxkoUser
}
export default connect(mapStateToProps, actions)(Login);

function createCookie(name, value) {
    let date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 5);
    const expires = "; expires=" + date.toGMTString();
    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(c_name) {
    if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + "=");
        if (c_start !== -1) {
            c_start = c_start + c_name.length + 1;
            let c_end = document.cookie.indexOf(";", c_start);
            if (c_end === -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}

function eraseCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
}
